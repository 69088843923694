<nz-layout class="web-page">
    <nz-header class="web-header">
        <div class="navigation-left">
            <li class="logo" routerLink="/home">
                <img src="/assets/images/menu/logo.png"/>
            </li>
            <ul nz-menu nzMode="horizontal">
                <nz-divider nzType="vertical"></nz-divider>
                <li nz-submenu nzTitle="产品服务">
                    <ul class="product-list">
                        <ng-container *ngFor="let item of productLists;let i = index;">
                            <li nz-menu-item [ngStyle]="{backgroundImage: 'url(' + item.bgUrl + ')'}" (click)="goTo(item.link)" [class.pointer]="item.link">
                                <img src="{{item.iconUrl}}"/>
                                <div class="product-name"><span>{{item.name}}</span></div>
                            </li>
                        </ng-container>
                    </ul>
                </li>
                <nz-divider nzType="vertical"></nz-divider>
                <li nz-menu-item routerLink="">
                    成功案例
                </li>
                <nz-divider nzType="vertical"></nz-divider>
                <li nz-menu-item routerLink="/news-center">
                    新闻中心
                </li>
                <nz-divider nzType="vertical"></nz-divider>
                <li nz-menu-item routerLink="/about">
                    关于坤盛
                </li>
                <nz-divider nzType="vertical"></nz-divider>
            </ul>
        </div>
        <div class="navigation-right">
            <div nz-dropdown [nzDropdownMenu]="menu" class="contact" [class.active]="dropdownVisible" (nzVisibleChange)="visibleChange($event)">
                <img src="/assets/images/menu/contact.png"/>联系我们
            </div>
            <nz-dropdown-menu #menu="nzDropdownMenu">
                <div nz-menu class="contact-dropdown">
                    <h1>广州市坤盛信息科技有限公司</h1>
                    <ul>
                        <li>
                            <img src="/assets/images/menu/phone.png"/>
                            020-38915459
                        </li>
                        <li>
                            <img src="/assets/images/menu/mail.png"/>
                            hr@kunsung.com.cn
                        </li>
                        <li>
                            <img src="/assets/images/menu/company.png"/>
                            广州市天河区中山大道西89号 国家软件园产业基地华景园区 A栋11楼北13-17
                        </li>
                    </ul>
                    <div class="contact-dropdown-bottom">
                        <div class="dropdown-bottom-wechat">
                            <img src="/assets/images/menu/qrcode.png"/>
                            <div class="dropdown-bottom-icon">
                                <img src="/assets/images/menu/wechat.png"/>
                                官方微信公众号
                            </div>
                        </div>
                        <div class="dropdown-bottom-map" (click)="goMap()">
                            <div class="map-mask">
                                <div id = "map" style="width:100%;height: 110px"></div>
                            </div>
                            <div class="dropdown-bottom-icon">
                                <img src="/assets/images/menu/baidu.png"/>
                                地图导航
                            </div>
                        </div>
                    </div>
                </div>
            </nz-dropdown-menu>
        </div>
    </nz-header>
    <nz-content>
        <div class="wen-content">
            <router-outlet></router-outlet>
        </div>
    </nz-content>
    <nz-footer class="web-footer">
        <div class="footer-left">
            <img src="/assets/images/menu/logo-foot.png"/>
        </div>
        <div class="footer-middle">
            <div class="footer-middle-item" *ngFor="let list of footLists;let i = index;">
                <h3>{{list.title}}</h3>
                <ul>
                    <li *ngFor="let item of list.values" [routerLink]="item.link">
                        {{item.name}}
                    </li>
                </ul>
            </div>
        </div>
        <div class="footer-right">
            <div class="footer-right-wechat">
                <img src="/assets/images/menu/qrcode.png"/>
                <div class="right-wechat-text">
                    <img src="/assets/images/menu/wechat-white.png"/>
                    关注官方微信公众号
                </div>

            </div>
            <p>广州市坤盛信息科技有限公司</p>
            <p><img src="/assets/images/menu/phone-white.png"/>020-38915459</p>
            <p><img src="/assets/images/menu/mail-white.png"/>hr@kunsung.com.cn</p>
            <p><img src="/assets/images/menu/company-white.png"/>广州市天河区中山大道西89号 华景软件园A栋11楼北13-17</p>
            <div class="copyright">
                <span>Copyright©广州市坤盛信息科技有限公司</span>
                <!-- <span>网络备案号：粤ICP备19092188号</span> -->
                <a href="https://beian.miit.gov.cn/" target="_blank">网络备案号：粤ICP备19092188号-1</a>
            </div>
        </div>
    </nz-footer>
</nz-layout>