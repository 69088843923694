import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const appRoutes: Routes = [
    {
        path: 'web',
        loadChildren: './web/web.module#WebModule',
        data: { preload: true }
    },
    {
        path: 'mobile',
        loadChildren: './mobile/mobile.module#MobileModule',
        data: { preload: true }
    }
  ];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { enableTracing: false, onSameUrlNavigation: 'reload' }, )],
    exports: [RouterModule]
})
export class AppRoutingModule { }
