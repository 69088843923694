import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './404/page-not-found.component';

export const routes: Routes = [
    // {
    //     path: '', component: FrameworkComponent,
    //     children: [
    //         { path: '**', component: PageNotFoundComponent, data: {title: '404'} },
    //     ]
    // }
    {
        path: '**',
        component: PageNotFoundComponent,
        data: {title: '404'}
      }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ]
})
export class AuthRoutingModule {}
