import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebComponent } from './web.component';
import { WebRoutingModule } from './web-routing.module';
import { NzLayoutModule, NzDividerModule, NzMenuModule, NzDropDownModule   } from 'ng-zorro-antd';
import { AuthModule } from './auth/auth.module';

@NgModule({
  declarations: [
    WebComponent
  ],
  exports: [WebComponent],
  imports: [
    CommonModule,
    WebRoutingModule,
    NzLayoutModule,
    NzDividerModule,
    NzMenuModule,
    NzDropDownModule,
    AuthModule
  ],
  providers: [],
  // bootstrap: [WebComponent]
})
export class WebModule { }
