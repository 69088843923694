import { NgModule }             from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { WebComponent } from './web.component';
import { HomeModule } from './home/home.module';
import { NewsCenterModule } from './news-center/news-center.module';
import { ProductModule } from './product/product.module';
import { AboutModule } from './about/about.module';


//web路由
const webRoutes: Routes = [
    {
      path: '',
      component: WebComponent,
      children: [
        // 首页
        {
          path: 'home',
          loadChildren: './home/home.module#HomeModule'
        },
        // 新闻中心
        {
          path: 'news-center',
          loadChildren: './news-center/news-center.module#NewsCenterModule'
        },
        // 产品介绍
        {
          path: 'product/:id',
          loadChildren: './product/product.module#ProductModule'
        },
        // 产品介绍
        {
          path: 'about',
          loadChildren: './about/about.module#AboutModule'
        },
        {
          path: '', // 路由不存在
          redirectTo: '/home',
          pathMatch: 'full'
        },
      ]
    }
  ];

@NgModule({
    imports: [
      CommonModule,
      RouterModule.forChild(webRoutes)
    ],
    exports: [
      RouterModule
    ],
    // providers: [
    // ]
  })
export class WebRoutingModule{}