export class KspSystemRequest {
    static kspSystemReq: KspSystemRequest = null;
    /**
     * KspSystemRequest 单例模式
     */
    static newstance(): KspSystemRequest {
        if (!this.kspSystemReq) {
            this.kspSystemReq = new KspSystemRequest();
        }
        return this.kspSystemReq;
    }

    QUERY_SYSTEM_CONFIG = '/security/common/systemConfig'; // 查询系统配置
    QUERY_NEWWS_LIST = '/security/article/queryInformationReleaseList'; // 新闻列表
    QUERY_NEWS_DETAIL = '/security/article/queryInformationRelease'; //新闻详情
    QUERY_WEATHER = '/security/article/queryWeatherForcast'; //天气

    /**
     * 请求系统配置
     */
    querySystemConfig(httpSer, reqParam, resopnseCB?):void{
        httpSer.httpRequest(this.QUERY_SYSTEM_CONFIG, reqParam, (httpResponse): void => {
            if (httpSer.HTTP_SUCCESS == httpResponse.status) {
                const result = httpResponse.result || {};
                if (resopnseCB) {
                    resopnseCB(result);
                }
            } else {
                console.log('请求系统配置Systemconfig失败');
            }
        });
    }

    /**
     * 查询新闻列表
     */
    queryNewsList(kspHttpSer, reqParam, resopnseCB?):void{
        kspHttpSer.httpRequest(this.QUERY_NEWWS_LIST, reqParam, (httpResponse): void => {
            if (kspHttpSer.HTTP_SUCCESS == httpResponse.status) {
                const httpData = httpResponse.result || {};
                const listData = httpData.dataList || [];
                if (resopnseCB) {
                    resopnseCB(listData);
                }
            } else {
                console.log('请求新闻列表失败');
            }
        });
    }

    /**
     * 查询新闻详情
     */
    queryNewsDetail(kspHttpSer, reqParam, resopnseCB?):void{
        kspHttpSer.httpRequest(this.QUERY_NEWS_DETAIL, reqParam, (httpResponse): void => {
            if (kspHttpSer.HTTP_SUCCESS == httpResponse.status) {
                const httpData = httpResponse.result || {};
                const listData = httpData.data || {};
                if (resopnseCB) {
                    resopnseCB(listData);
                }
            } else {
                console.log('请求新闻详情失败');
            }
        });
    }

    /**
     * 查询新闻详情
     */
    queryWeather(kspHttpSer, reqParam, resopnseCB?):void{
        kspHttpSer.httpRequest(this.QUERY_WEATHER, reqParam, (httpResponse): void => {
            if (kspHttpSer.HTTP_SUCCESS == httpResponse.status) {
                const httpData = httpResponse.result || {};
                const listData = httpData.data || {};
                if (resopnseCB) {
                    resopnseCB(listData);
                }
            } else {
                console.log('请求天气失败');
            }
        });
    }
}