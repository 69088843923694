import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductList, FootList } from './web-config-data.module';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
declare var BMap: any;

@Component({
    selector: 'web-main',
    templateUrl: './web.component.html',
    styleUrls: ['./web.component.less']
})
export class WebComponent implements OnInit, OnDestroy {
    productLists: Array<any> = ProductList;
    footLists: Array<any> = FootList;
    dropdownVisible: Boolean = false;
    map: any; // 地图
    subscription: Subscription;
    isClick: Boolean = false;
    constructor(private router: Router) {
        this.subscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                console.log('监听');
                // document.body.scrollTop=0;
                window.scrollTo(0, 0);
            }
        });
    }

    ngOnInit() {

    }

    visibleChange(e: any) {
        if (e) {
            this.dropdownVisible = true;
            if (!this.map) {
                setTimeout(() => {
                    this.map = new BMap.Map("map"); //创建地图实例
                    let point = new BMap.Point(39.897445,116.331398); //创建点坐标
                    this.map.centerAndZoom(point,12); //初始化地图，设置中心点坐标和地图级别
                    // 创建地址解析器实例
                    var myGeo = new BMap.Geocoder();
                    const self = this;
                    // 将地址解析结果显示在地图上,并调整地图视野
                    myGeo.getPoint("广州市天河区中山大道西89号 国家软件园产业基地华景园区 A栋11楼北13-17", function(point){
                        if (point) {
                            self.map.centerAndZoom(point, 16);
                            self.map.addOverlay(new BMap.Marker(point));
                        }else{
                            alert("您选择地址没有解析到结果!");
                        }
                    }, "广州市");
                }, 300);
            }
        } else {
            this.dropdownVisible = false;
        }
    }

    goMap() {
        // 跳转百度地图
        window.open('http://api.map.baidu.com/geocoder?address=广州市天河区中山大道西89号 国家软件园产业基地华景园区 A栋11楼北13-17&output=html&src=webapp.baidu.openAPIdemo', '_blank');
    }

    goTo(link: any) {
        if (link) {
            this.isClick = false;
            this.openChange(false);
            this.router.navigate([link]);
        }
    }

    openChange(e: any) {
        // console.log('eee', e);
        // if (!e) {
        //     this.isClick = false;
        // } else {
        //     this.isClick = true;
        // }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
