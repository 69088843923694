export const ProductList = [
    { name: '森林资源监控', bgUrl: '/assets/images/index/product/product-bg1.png', iconUrl: '/assets/images/index/product/product-icon1.png', link: '/product/forest'},
    { name: '智慧水务', bgUrl: '/assets/images/index/product/product-bg2.png', iconUrl: '/assets/images/index/product/product-icon2.png', link: '/product/water' },
    { name: '无人机与测绘', bgUrl: '/assets/images/index/product/product-bg3.png', iconUrl: '/assets/images/index/product/product-icon3.png' },
    { name: '系统集成', bgUrl: '/assets/images/index/product/product-bg4.png', iconUrl: '/assets/images/index/product/product-icon4.png' },
    { name: '林长制', bgUrl: '/assets/images/index/product/product-bg5.png', iconUrl: '/assets/images/index/product/product-icon5.png', link: '/product/chief' },
    { name: '智慧燃气', bgUrl: '/assets/images/index/product/product-bg6.png', iconUrl: '/assets/images/index/product/product-icon6.png', link: '/product/fuelgas' },
    { name: '智慧园区', bgUrl: '/assets/images/index/product/product-bg7.png', iconUrl: '/assets/images/index/product/product-icon7.png' },
    { name: '智能硬件', bgUrl: '/assets/images/index/product/product-bg8.png', iconUrl: '/assets/images/index/product/product-icon8.png' },
];

export const FootList = [
    { title : '产品服务', values: [
        {name: '森林资源监测', link: '/product/forest'},
        {name: '林长制', link: '/product/chief'},
        {name: '无人机与测绘', link: ''},
        {name: '智慧园区', link: ''},
        {name: '智慧水务', link: '/product/water'},
        {name: '智慧燃气', link: '/product/fuelgas'},
        {name: '系统集成', link: ''},
        {name: '智能硬件', link: ''}
    ]},
    { title : '成功案例', values: [
        {name: '智慧水务生产运营管理信息化平台', link: ''},
        {name: '智慧水务运营管理服务平台', link: ''},
        {name: '某某燃气气量挖掘管理平台', link: ''},
        {name: '某某气量调度管理平台', link: ''}
    ]},
    { title : '新闻中心', values: [
        {name: '智慧平台', link: ''},
        {name: '5G', link: ''},
        {name: '物联网', link: ''},
        {name: '人工智能', link: ''}
    ]},
    { title : '关于坤盛', values: [
        {name: '联系我们', link: '/about'},
        {name: '招聘动态', link: ''},
    ]},
];
