import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KspSystemConfig } from 'ksp-platform';
import { KspHttpService } from './service/http.service';;
import { KspSystemRequest } from './service/system-request.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isCollapsed = false;

  constructor(public router: Router, private route: ActivatedRoute, public kspHttpSer: KspHttpService){

  }

  ngOnInit(){
    console.log('app-root onInit');
    this.browerJudge();
    this.getSystemConfig();
    // this.router.navigate(['/mobile']);
  }

  /**
   * 浏览器类型判断
   */
  browerJudge() {
    const u = navigator.userAgent;
    const app = navigator.appVersion;
    const browser = { //移动终端浏览器版本信息
        trident: u.indexOf('Trident') > -1, //IE内核
        presto: u.indexOf('Presto') > -1, //opera内核
        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
        language: (navigator.language).toLowerCase()
    }
    
    if (browser.mobile) { //判断是否是移动设备打开。browser代码在上面
        // this.router.navigate(['/mobile']);
        const ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
        // this.route.url.subscribe(url => console.log('current url',url));
        
        if (ua.match(/MicroMessenger/i) && "micromessenger" === ua.match(/MicroMessenger/i).toString()) {
            //在微信中打开
            
            // return BrowerType.WX;
        }
        
        if (ua.match(/WeiBo/i) && ua.match(/WeiBo/i).toString() == "weibo") {
            //在新浪微博客户端打开
            
            // return BrowerType.Sina;
        }
        
        if (ua.match(/QQ/i) && ua.match(/QQ/i).toString() == "qq") {
            //在QQ空间打开
            
            // return BrowerType.QQZone;
        }
      
        if (browser.ios) {
            //是否在IOS浏览器打开
            
            // return BrowerType.IOS;
        }
      
        if (browser.android) {
            //是否在安卓浏览器打开
            
            // return BrowerType.Android;
        }else{
          
            // return BrowerType.Other;
        }
    } else {
        //否则就是PC浏览器打开
        this.router.navigate(['/web']);
        // return BrowerType.PC;
    }
  }

  /**
   * 请求系统配置
   */
  getSystemConfig() {
    const kspHttpReq = KspSystemRequest.newstance();
    const self = this;
    kspHttpReq.querySystemConfig(this.kspHttpSer, {}, (result) => {
      self.handleSystem(result || {});
    });
  }

  /**
   * 设置系统配置
   */
  handleSystem(result) {
    const mapToken = result.mapToken;
    const addressUtil = result.addressUtil || {};
    const systemConfig = result.systemConfig || {};
    if (addressUtil.newstance) {
      KspSystemConfig.newstance().baseUploadUrl = addressUtil.newstance.fullBaseUploadUrl;
      KspSystemConfig.newstance().baseImageUrl = addressUtil.newstance.baseImageUrl;
    }
  }
}
