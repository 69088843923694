import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WebModule } from './web/web.module';
import { MobileModule } from './mobile/mobile.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { KspReuseStrategy, KspLoadingModule, KspProgressBarModule } from 'ksp-platform';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_CN } from 'ng-zorro-antd/i18n';
import { registerLocaleData, LocationStrategy, HashLocationStrategy } from '@angular/common';
import zh from '@angular/common/locales/zh';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    WebModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    KspLoadingModule,
    KspProgressBarModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    { provide: RouteReuseStrategy, useClass: KspReuseStrategy },
    {provide: LocationStrategy, useClass: HashLocationStrategy}, // 路由刷新404问题
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
