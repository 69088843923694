/**
 * @Author: Circle
 * @Description: http通信服务
 * @Date: 2020-01-23
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { shareReplay, retry, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { KspSystemConfig, KspLocalStorage, HttpRequestModel, HttpResponse } from 'ksp-platform';
@Injectable({
  providedIn: 'root'
})
export class KspHttpService {
  static SUCCESS_STATUS = '0000';
  static EXPIRE_STATUS = '402';
  static token = 'token'; // token标识
  public HTTP_SUCCESS = '0000'; // 请求成功状态码
  public RETRY = 1; // 重试次数

  constructor(private http: HttpClient, public router: Router, public local: KspLocalStorage, private toast: NzMessageService) { }

  /**
   * htpp请求
   * @param url 接口地址
   * @param body 请求参数
   * @param isShow 是否显示加载动画,不传或者传true则加载动画
   * @param callback 回调函数
   */
  public httpRequest(url, body, callback?: (httpResponse: HttpResponse) => void, isShow?) {
    const request = new HttpRequestModel();
    request.type = 'post';
    request.url = url;
    request.body = JSON.stringify(body);
    request.callback = callback;
    request.isShowLoading = isShow;
    this.request(request);
  }

  /**
   * 请求分发
   * @param options 请求配置参数
   */
  private request(options: HttpRequestModel) {
    if (options.type == 'get') { // get请求
      this.get(options.url, options.callback, options.params, options.isShowLoading);
    } else { // post 请求
      this.post(options.url, options.body, options.callback, options.params, options.isShowLoading);
    }
  }

  /**
   * get请求方法
   * @param url 接口地址
   * @param param 请求参数
   * @param callback 回调函数
   */
  private get(url: string, callback?: (httpResponse: HttpResponse) => void, param?: Map<string, string>, isShowLoading?: boolean) {
    const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
    const self = this;
    const params: HttpParams = new HttpParams();
    if (param) {
      (param as Map<string, string>).forEach((value, key, map) => {
        params.set(key, value);
      });
    }

    // if (this.local.get(KspLoginModel.loginUser) && (<KspLoginModel>this.local.get(KspLoginModel.loginUser)).token) {
    //   params.set(KspHttpService.token, (<KspLoginModel>this.local.get(KspLoginModel.loginUser)).token);
    // } else {
    //   //如果没有token，则直接重新登陆
    //   if (url !== KspSystemConfig.newstance().loginUrl) { //如果请求是登陆请求，则不需要检查token
    //     this.router.navigate([KspSystemConfig.newstance().webLoginRoute]);
    //     return;
    //   }
    // }
    //如果没有传值和确定要显示loading的，则进行显示loading
    if (isShowLoading == undefined || isShowLoading) {
      //显示加载框
    //   this.loadingSer.start();
    }

    this.http.get<HttpResponse>(url, { headers, params }).pipe(
      retry(this.RETRY),
      shareReplay(),
      timeout(1000 * 2 * 60)
    ).subscribe(
      data => {
        return self.handleSuccess(data, callback);
      },
      (err: HttpErrorResponse) => {
        return self.handleError(err, callback);
      });
  }

  /**
   * post请求方法
   * @param url 接口地址
   * @param body 请求体
   * @param callback 回调函数
   */
  private post(url: string, body: any, callback?: (httpResponse: HttpResponse) => void, param?: Map<string, string>, isShowLoading?: boolean) {
    const self = this;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' }).set('Access-Control-Allow-Origin', '*');
    const params: HttpParams = new HttpParams();
    if (param) {
      (param as Map<string, string>).forEach((value, key, map) => {
        params.set(key, value);
      });
    }

    // if (this.local.get(KspLoginModel.loginUser) && (<KspLoginModel>this.local.get(KspLoginModel.loginUser)).token) {
    //   params.set(KspHttpService.token, (<KspLoginModel>this.local.get(KspLoginModel.loginUser)).token);
    // } else {
    //   //如果没有token，则直接重新登陆
    //   if (url !== KspSystemConfig.newstance().loginUrl) { //如果请求是登陆请求，则不需要检查token
    //     this.router.navigate([KspSystemConfig.newstance().webLoginRoute]);
    //     return;
    //   }

    // }
    //如果没有传值和确定要显示loading的，则进行显示loading
    if (isShowLoading == undefined || isShowLoading) {
      //显示加载框
    //   this.loadingSer.start();
    }

    this.http.post<HttpResponse>(url, body, { headers, params }).pipe(
      retry(this.RETRY),
      shareReplay(),
      timeout(1000 * 2 * 60)
    ).subscribe(
      data => {
        return self.handleSuccess(data, callback);
      },
      (err: HttpErrorResponse) => {
        return self.handleError(err, callback);
      });
  }

  /**
   * 请求成功的处理方法
   * @param data 返回的数据
   * @param callback 回调函数
   */
  handleSuccess(data: any, callback?: (httpResponse: HttpResponse) => void): HttpResponse {
    const httpResponse: HttpResponse = new HttpResponse();
    // 隐藏加载框
    // this.loadingSer.end();

    httpResponse.result = data;
    httpResponse.status = data.code;
    if (data.code != KspHttpService.SUCCESS_STATUS && data.message) {
      // this.message.error(data.message);
    }
    callback ? callback(httpResponse) : console.log('没有回调函数');
    return httpResponse;
  }

  /**
   * 请求失败的处理方法
   * @param err 捕获的错误
   * @param callback 回调函数
   */
  handleError(err: HttpErrorResponse, callback?: (httpResponse: HttpResponse) => void) {
    const httpResponse: HttpResponse = new HttpResponse();
    // 隐藏加载框
    // this.loadingSer.end();

    if (err.error instanceof ErrorEvent) {// A client-side or network error occurred.

      httpResponse.status = err.status;
      httpResponse.httpErrorResponse = err;
      httpResponse.message = err.message;

      callback ? callback(httpResponse) : console.log('没有传入回调函数');
      console.log(`客户端错误，返回的status： ${err.status}, body was: ${err.error},the code is ${err.statusText}`);
      // this.message.warning('网络异常，请稍后重试');

    } else { // The backend returned an unsuccessful response code.

      if (err && err.error) {
        httpResponse.status = err.error.status;
        httpResponse.message = err.error.message;
        httpResponse.httpErrorResponse = err.error;
      }
      callback ? callback(httpResponse) : console.log('没有传入回调函数');
      console.log(`服务器错误，返回的status： ${err.status}, body was: ${err.error},the code is ${err.statusText}`);
      let msg = httpResponse.message ? httpResponse.message : '';
      if (msg) {
        this.toast.warning(msg);
      }
    //   if (httpResponse.status == KspHttpService.EXPIRE_STATUS) {
    //     this.goLogin();
    //   }
    }
    return httpResponse;
  }

  /**
   * 强制重新登录
   */
  goLogin() {
    // this.local.remove(KspLoginModel.loginUser);//清除登陆token
    // this.router.navigate(['/web/login']);
  }

}
