import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './404/page-not-found.component';
import { AuthRoutingModule } from './auth-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule, NzFormModule, NzIconModule, NzSelectModule, NzDividerModule } from 'ng-zorro-antd';

@NgModule({
    declarations: [
        PageNotFoundComponent,
    ],
    entryComponents: [],
    exports: [],
    providers: [  ],
    imports: [
        CommonModule,
        RouterModule,
        AuthRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NzInputModule,
        NzFormModule,
        NzIconModule,
        NzSelectModule,
        NzDividerModule,
    ]
})
export class AuthModule { }
